const firebaseConfig = {
  apiKey: "AIzaSyA6miG1egwhyCpNcBdr-R8P3flUJP8VLj4",
  authDomain: "squr-d2ae8.firebaseapp.com",
  databaseURL:
    "https://squr-d2ae8-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "squr-d2ae8",
  storageBucket: "squr-d2ae8.appspot.com",
  messagingSenderId: "25610237851",
  appId: "1:25610237851:web:8b825f5e7ab2363cd2533e",
};

export default firebaseConfig;
